<template>
  <div class="content">
    <div class="main-screen-section inside screen6"></div>

    <div class="container inside-page content-section">
      <h1>
        Профилактическое лечение мигрени<ReferenceLink text="1" href="#ref1" />
      </h1>
      <h3>Цели профилактического лечения мигрени:</h3>
      <ul class="list-block">
        <li>
          <p>
            уменьшение количества дней с головной болью или мигренью на 50%;
          </p>
        </li>
        <li>
          <p>значительное уменьшение продолжительности приступа;</p>
        </li>
        <li>
          <p>значительное снижение тяжести приступа;</p>
        </li>
        <li>
          <p>улучшенный ответ на медикаментозное лечение острого приступа;</p>
        </li>
        <li>
          <p>
            снижение инвалидности, связанной с мигренью, и улучшение
            функционирования в важных сферах жизни;
          </p>
        </li>
        <li>
          <p>
            улучшение качества жизни и снижение психологического дискомфорта
            из-за мигрени.
          </p>
        </li>
      </ul>
      <p class="mt-4">
        Для оценки поставленных целей необходимо настоятельно рекомендовать
        пациентам вести дневник головной боли.
      </p>
      <br />
      <div class="content-text text-size-middle">
        <h3>
          Показания для профилактического лечения мигрени<ReferenceLink
            text="1"
            href="#ref1"
          />:
        </h3>
        <p>
          Рекомендуется профилактическое лечение пациентам с эпизодической
          мигренью с частотой приступов 4 в месяц и более, пациентам с
          хронической мигренью, а также пациентам с тяжёлой и пролонгированной
          аурой, даже при небольшой частоте приступов.
        </p>
        <p class="mt-4">
          Кроме того, профилактическая терапия мигрени показана в следующих
          случаях:
        </p>

        <ul class="list-block">
          <li>
            <p>существенная дезадаптация из-за приступов мигрени;</p>
          </li>
          <li>
            <p>
              коморбидные нарушения в межприступном периоде, нарушающие качество
              жизни;
            </p>
          </li>
          <li>
            <p>
              неэффективность или плохая переносимость препаратов для
              купирования приступов мигрени;
            </p>
          </li>
          <li>
            <p>наличие лекарственного абузуса;</p>
          </li>
          <li>
            <p>гемиплегическая мигрень;</p>
          </li>
          <li>
            <p>мигрень со стволовой аурой;</p>
          </li>
          <li>
            <p>мигренозный статус или мигренозный инсульт в анамнезе.</p>
          </li>
        </ul>
        <p class="mt-4">
          Профилактическое лечение считается эффективным, если частота дней с ГБ
          сокращается через 3 месяца от начала терапии на 50% и более от
          исходной. 
        </p>

        <h3 class="mt-5">
          Таргетная терапия<ReferenceLink text="2" href="#ref2" />
        </h3>
        <p>
          Ни одно из применяемых в настоящее время лекарственных средств
          (антидепрессанты, антиконвульсанты, бета-адреноблокаторы, блокаторы
          кальциевых каналов) не является специфичным к мигрени.
        </p>
        <p>
          Однако последние достижения в понимании патофизиологии мигрени дали
          возможность <br class="visible-xs" />
          для разработки новых фармакотерапевтических подходов, специально
          направленных на различные нейрональные механизмы .
        </p>
        <p>
          Главным новшеством стал таргетный подход — нацеленность на
          специфические нейробиологические мишени. В настоящее время лидирующей
          является стратегия, направленная на нейробиологию CGRP.
        </p>
      </div>
      <div class="img-block">
        <img src="img/therapy.jpeg" class="img-responsive" alt="img" />
      </div>

      <div class="content-text text-size-middle">
        <div class="title-green">
          Фреманезумаб (Аджови)<ReferenceLink text="4" href="#ref4" />
        </div>

        <p>
          <strong>Показания к применению:</strong> Аджови показан к применению
          для профилактики мигрени у взрослых, имеющих по меньшей мере 4 дня с
          мигренью в месяц.
        </p>
        <p>
          <strong>Механизм действия:</strong> Фреманезумаб — это
          гуманизированное моноклональное антитело иммуноглобулина (Ig)
          G2Δa/каппа (IgG2Δa), полученное из мышиного прекурсора.Фреманезумаб
          избирательно связывает лиганд кальцитонин ген-родственного пептида
          (КГРП) и блокирует обе изоформы КГРП (α- и β-КГРП) от связывания с
          рецептором КГРП. Несмотря на то, что точный механизм действия,
          благодаря которому фреманезумаб предотвращает приступы мигрени,
          неизвестен, считается, что предупреждение приступов мигрени
          достигается благодаря модуляции (влиянию на систему) системы
          тройничного нерва. Было обнаружено, что уровень КГРП существенно
          увеличивается во время мигрени и приходит в норму, когда головная боль
          проходит.
        </p>
        <br />

        <h3 class="mb30">Механизм действия</h3>

        <div class="video-block">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/s3DnsGMSjZY"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <!-- <span class="play-ico"></span> -->
        </div>

        <h3>
          Фреманезумаб. Особые указания и меры предосторожности при
          применении<ReferenceLink text="4" href="#ref4" />
        </h3>
        <p class="mt-4">
          В ходе клинических исследований, менее чем у 1% пациентов были
          зарегистрированы реакции гиперчувствительности при применении
          фреманезумаба. При возникновении реакции гиперчувствительности,
          необходимо рассмотреть прекращение введения фреманезумаба и начать
          соответствующую терапию.
        </p>
        <p>
          Вспомогательные вещества: в этом лекарственном средстве содержится
          менее 1 ммоль натрия (23 мг) на дозу, таким образом оно является
          практически «безнатриевым». Один предварительно заполненный шприц
          содержит в качестве стабилизатора 99 мг сахарозы.
        </p>
        <br />
        <div class="instructions">
          <a
            href="pdfs/ОХЛП_Аджови_р_р_подкожный_225мг_1_5мл_03_04_2021_рус_final.pdf"
            class="instruction-link"
            target="_blank"
          >
            Аджови ОХЛП рус<ReferenceLink text="4" href="#ref4" />
          </a>
          <!-- https://teva-ajovy-api.rocketfirm.net/storage/files/pdf/general-info.pdf -->
          <br class="visible-xs" />
          <a
            href="pdfs/ОХЛП_Аджови_р_р_подкожный_225мг_1_5мл_03_04_2021_каз_final.pdf"
            target="_blank"
            class="instruction-link"
          >
            Аджови ОХЛП каз<ReferenceLink text="4" href="#ref4" />
          </a>
        </div>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'

export default {
  name: 'therapy',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Лечение',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'КЛИНИЧЕСКИЙ ПРОТОКОЛ ДИАГНОСТИКИ И ЛЕЧЕНИЯ «МИГРЕНЬ У ВЗРОСЛЫХ». Одобрено ОКМУМЗ РК от «07» сентября 2023 года. Протокол №188'
        },
        {
          id: 'ref2',
          text: 'Ashina M. N Engl J Med. 2020 Nov 5;383(19):1866-1876'
        },
        {
          id: 'ref3',
          text: 'Schoenen J. Rev Neurol (Paris). 2020 Dec;176(10):788-803'
        },
        {
          id: 'ref4',
          text: 'Аджови. Общая характеристика лекарственного препарата. Утверждена приказом прелседателя РГУ “КФМК МЗ РК” от “29” марта 2024 г. №073084'
        }
      ]
    }
  }
}
</script>
